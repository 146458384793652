/* Start Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;600&family=Roboto:wght@300;400&display=swap');

/*
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Slab', serif;
*/

/* End Fonts */

/* Import CSS Files */
@import './auth.css';
@import './navbar.css';
@import './dashboard.css';

/* Start Global */
html,
body,
#root {
  padding: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Slab', serif;
}

p,
li {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
}

a {
  color: inherit;
  text-decoration: none;
}

[role=button] {
  cursor: pointer;
}

/* Utilities */
.main-font-family {
  font-family: 'Roboto', sans-serif;
}

.link {
  text-decoration: underline;
}

.hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.loader {
  position: relative;
  height: 100vh;
}

.loader span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: large;
  font-weight: 500;
}

.loader .spinner {
  border: 3px solid blueviolet;
  border-top: 0;
  border-right: 0;
  border-radius: 50%;
  width: 115px;
  height: 115px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: Loader 1.5s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@media all and (max-width: 640px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

/* End Global */

/* Start Header */
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header .icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 50px;
}

.header .icons div {
  position: relative;
  cursor: pointer;
  margin: 0 3px;
}

.header .icons div span.red-dot {
  background-color: red;
  padding: 5px 5px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 0;
  z-index: 1;
}

.header .icons div:nth-child(1) span.red-dot {
  top: 5px;
  right: 3px;
}

.header .profile-info {
  position: relative;
}

.header .profile-info .image {
  max-width: 77px;
  max-height: 77px;
  cursor: pointer;
}

.header .profile-info .info {
  position: absolute;
  top: 90px;
  right: 50%;
  transform: translateX(15%);
  width: 180px;
  background-color: #4d4f5c;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  transition: 0.5s;
  z-index: 10;
}

.header .profile-info .info::after {
  content: '';
  border: 10px solid #4d4f5c;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  top: -17px;
  right: 18px;
  z-index: 1;
}

.header .profile-info .info h5 {
  color: #828bb2;
  font-size: 13px;
}

.header .profile-info .info h6 {
  font-size: 12px;
  text-align: center;
}

.header .profile-info .info ul li a {
  font-size: 14px !important;
}

@media screen and (min-width: 991.99px) {
  .header {
    padding-top: 0 !important;
    height: 280px;
  }
}

/* End Header */

/* Start Base Alert */
.base-alert {
  top: -260px;
  left: 30px;
  width: 430px;
  filter: drop-shadow(0 0 8px #ffffff20);
}

@media screen and (max-width: 992px) {
  .base-alert {
    position: static !important;
    margin-top: 20px;
    width: 90%;
  }
}

/* Animation */
@keyframes Loader {
  from {
    transform: translate(-50%, -50%) rotate(-360deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
