.cards .card {
  width: 240px;
}

.cards .card .card-header,
.cards svg {
  color: #fff;
  font-size: 25px;
}

.cards .card .increment-percent svg {
  color: rgb(180, 180, 180);
}

.cards .card .card-header h3 {
  font-size: 19px;
  text-transform: uppercase;
}

.cards .card .card-body .increment-percent {
  color: #30996c;
  font-weight: 600;
}

/* Start Charts */
.charts div {
  width: 420px;
}

@media screen and (max-width: 360px) {
  .charts div {
    width: 250px;
  }
}

@media screen and (min-width: 361px) and (max-width: 767.99) {
  .charts div {
    width: 360px;
  }
}

@media screen and (min-width: 991.99px) {
  .cards {
    margin-top: -85px;
    margin-bottom: 10px;
  }
}

/* End Charts */

/* Start Base Table */
.base-table h2 {
  text-align: center;
}

.base-table table {
  min-height: 250px;
}

.base-table table .field {
  border: 1px solid;
  background: #fff;
  padding: 1px;
  border-radius: 3px;
}

.base-table table .field:disabled {
  padding: 0;
  border: 0;
  outline: 0;
}

.base-table table .actions button.btn {
  transition: 0.5s;
}

@media screen and (max-width: 991.99px) {
  .base-table {
    overflow-x: scroll;
  }

  .base-table h2 {
    width: 147px;
  }

  .base-table table {
    table-layout: initial;
  }

  .base-table .btn {
    margin-bottom: 6px;
  }

  .base-table .actions .btn {
    width: 125px;
    padding: 5px;
  }
}

@media screen and (min-width: 992px) {
  .base-table table {
    table-layout: fixed;
    overflow: hidden;
  }

  .base-table tr,
  .base-table tr td input {
    text-align: center;
  }

  .base-table table .field {
    width: 100%;
  }
}

/* End Base Table */

/* Start Popup Form */
.pop-up-form {
  z-index: 100;
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
}

.pop-up-form svg {
  float: right;
  cursor: pointer;
  font-size: 25px;
  margin: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.pop-up-form form {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
}

.pop-up-form form .form-control {
  background-color: inherit !important;
  border: none !important;
  text-align: left;
}

.pop-up-form form .form-control input {
  border-radius: 5px;
  outline: none;
  border: 1px solid;
}

.pop-up-form form .form-control .error {
  color: red;
}

.pop-up-form form .btn {
  width: 50%;
  font-size: 20px;
}

@media screen and (max-width: 576px) {
  .pop-up-form form {
    transform: translateY(10%);
  }
}

/* End Popup Form */
