.auth .container,
.auth .row {
  min-height: 100vh;
}

.auth .form-title {
  font-size: 50px !important;
  padding-inline: 0.75rem;
}

.auth .form-control {
  background-color: transparent;
  border: none;
}

.auth label {
  color: #fff;
}

.auth input {
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 2px 5px;
  filter: drop-shadow(0 0 10px #ffffff30);
}

.auth .error {
  color: #eed754;
  margin-top: 5px;
}

.auth .or-separator {
  position: relative;
}

.auth .or-separator::after {
  background: #fff;
  content: "";
  display: block;
  width: 35%;
  height: 1px;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-120%);
}

.auth .or-separator::before {
  background: #fff;
  content: "";
  display: block;
  width: 35%;
  height: 1px;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(20%);
}

.auth .singing-icon {
  height: 30px;
  width: 30px;
}

.auth .submit {
  outline: none;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.2s ease-out;
  margin: 20px 12px;
}

.auth .submit:hover {
  background-color: #fe9a02;
  border-color: #fe9a02;
  color: rgb(11, 10, 10);
}

.auth .submit:focus-visible {
  border-color: #000;
}

.auth .submit:disabled {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  cursor: not-allowed;
}

.auth .links a:hover {
  color: rgb(13, 13, 136);
}
