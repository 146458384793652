nav {
  position: relative;
  transition: 0.5s;
  color: black;
}

nav > div,
nav > ul {
  position: fixed;
}

nav .navbar-toggler {
  display: none;
  padding: 0;
}

nav .close-icon {
  padding: 0;
  position: absolute;
  right: -107px;
  top: -34px;
  font-size: 22px;
  color: rgb(202, 199, 199);
}

nav .navbar-toggler:focus,
nav .close-icon:focus {
  box-shadow: none !important;
}

nav .navbar-logo span,
nav .navbar-logo svg {
  letter-spacing: 2px;
  color: #007bff;
  font-size: 25px;
  font-weight: 600;
  font-variant: small-caps;
  font-family: 'Roboto', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

nav .nav {
  transition: 0.2s;
}

nav .nav .nav-item .btn {
  box-shadow: none !important;
  border: 0;
  padding: 0;
  margin-left: 10px;
}

nav .nav .nav-item a,
nav .nav .nav-item svg,
nav .nav .nav-item .btn span {
  font-size: 17px !important;
  font-weight: 400;
  color: #676b84 !important;
  transition: 0.5s;
}

nav .nav .nav-item svg {
  font-size: 22px !important;
  margin-right: 10px;
}

nav .nav .nav-item:hover a,
nav .nav .nav-item:hover svg,
nav .nav .nav-item:hover .btn span {
  color: rgba(0, 0, 0, 0.774) !important;
}

nav.close {
  padding: 0 !important;
}

nav.close .navbar-logo {
  display: none !important;
}

nav.close .navbar-toggler {
  display: block;
  opacity: 1;
  visibility: visible;
  border: none;
  margin: 15px 0 0 0 !important;
  align-self: center;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
}

nav.close .close-icon {
  transition: 0.2s;
  opacity: 0;
}

nav.close .nav.closed-nav {
  top: 50px;
}

nav.close .nav.closed-nav .nav-item .nav-link svg {
  font-size: 22px;
  margin: 15px 0;
}

@media screen and (max-width: 575.99px) {
  nav .close-icon {
    top: 10px;
    left: 90%;
  }
}
