@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;600&family=Roboto:wght@300;400&display=swap);
.auth .container,
.auth .row {
  min-height: 100vh;
}

.auth .form-title {
  font-size: 50px !important;
  padding-inline: 0.75rem;
}

.auth .form-control {
  background-color: transparent;
  border: none;
}

.auth label {
  color: #fff;
}

.auth input {
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 2px 5px;
  -webkit-filter: drop-shadow(0 0 10px #ffffff30);
          filter: drop-shadow(0 0 10px #ffffff30);
}

.auth .error {
  color: #eed754;
  margin-top: 5px;
}

.auth .or-separator {
  position: relative;
}

.auth .or-separator::after {
  background: #fff;
  content: "";
  display: block;
  width: 35%;
  height: 1px;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: 50%;
  -webkit-transform: translateX(-120%);
          transform: translateX(-120%);
}

.auth .or-separator::before {
  background: #fff;
  content: "";
  display: block;
  width: 35%;
  height: 1px;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: 50%;
  -webkit-transform: translateX(20%);
          transform: translateX(20%);
}

.auth .singing-icon {
  height: 30px;
  width: 30px;
}

.auth .submit {
  outline: none;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.2s ease-out;
  margin: 20px 12px;
}

.auth .submit:hover {
  background-color: #fe9a02;
  border-color: #fe9a02;
  color: rgb(11, 10, 10);
}

.auth .submit:focus-visible {
  border-color: #000;
}

.auth .submit:disabled {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  cursor: not-allowed;
}

.auth .links a:hover {
  color: rgb(13, 13, 136);
}

nav {
  position: relative;
  transition: 0.5s;
  color: black;
}

nav > div,
nav > ul {
  position: fixed;
}

nav .navbar-toggler {
  display: none;
  padding: 0;
}

nav .close-icon {
  padding: 0;
  position: absolute;
  right: -107px;
  top: -34px;
  font-size: 22px;
  color: rgb(202, 199, 199);
}

nav .navbar-toggler:focus,
nav .close-icon:focus {
  box-shadow: none !important;
}

nav .navbar-logo span,
nav .navbar-logo svg {
  letter-spacing: 2px;
  color: #007bff;
  font-size: 25px;
  font-weight: 600;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  font-family: 'Roboto', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

nav .nav {
  transition: 0.2s;
}

nav .nav .nav-item .btn {
  box-shadow: none !important;
  border: 0;
  padding: 0;
  margin-left: 10px;
}

nav .nav .nav-item a,
nav .nav .nav-item svg,
nav .nav .nav-item .btn span {
  font-size: 17px !important;
  font-weight: 400;
  color: #676b84 !important;
  transition: 0.5s;
}

nav .nav .nav-item svg {
  font-size: 22px !important;
  margin-right: 10px;
}

nav .nav .nav-item:hover a,
nav .nav .nav-item:hover svg,
nav .nav .nav-item:hover .btn span {
  color: rgba(0, 0, 0, 0.774) !important;
}

nav.close {
  padding: 0 !important;
}

nav.close .navbar-logo {
  display: none !important;
}

nav.close .navbar-toggler {
  display: block;
  opacity: 1;
  visibility: visible;
  border: none;
  margin: 15px 0 0 0 !important;
  align-self: center;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
}

nav.close .close-icon {
  transition: 0.2s;
  opacity: 0;
}

nav.close .nav.closed-nav {
  top: 50px;
}

nav.close .nav.closed-nav .nav-item .nav-link svg {
  font-size: 22px;
  margin: 15px 0;
}

@media screen and (max-width: 575.99px) {
  nav .close-icon {
    top: 10px;
    left: 90%;
  }
}

.cards .card {
  width: 240px;
}

.cards .card .card-header,
.cards svg {
  color: #fff;
  font-size: 25px;
}

.cards .card .increment-percent svg {
  color: rgb(180, 180, 180);
}

.cards .card .card-header h3 {
  font-size: 19px;
  text-transform: uppercase;
}

.cards .card .card-body .increment-percent {
  color: #30996c;
  font-weight: 600;
}

/* Start Charts */
.charts div {
  width: 420px;
}

@media screen and (max-width: 360px) {
  .charts div {
    width: 250px;
  }
}

@media screen and (min-width: 361px) and (max-width: 767.99) {
  .charts div {
    width: 360px;
  }
}

@media screen and (min-width: 991.99px) {
  .cards {
    margin-top: -85px;
    margin-bottom: 10px;
  }
}

/* End Charts */

/* Start Base Table */
.base-table h2 {
  text-align: center;
}

.base-table table {
  min-height: 250px;
}

.base-table table .field {
  border: 1px solid;
  background: #fff;
  padding: 1px;
  border-radius: 3px;
}

.base-table table .field:disabled {
  padding: 0;
  border: 0;
  outline: 0;
}

.base-table table .actions button.btn {
  transition: 0.5s;
}

@media screen and (max-width: 991.99px) {
  .base-table {
    overflow-x: scroll;
  }

  .base-table h2 {
    width: 147px;
  }

  .base-table table {
    table-layout: initial;
  }

  .base-table .btn {
    margin-bottom: 6px;
  }

  .base-table .actions .btn {
    width: 125px;
    padding: 5px;
  }
}

@media screen and (min-width: 992px) {
  .base-table table {
    table-layout: fixed;
    overflow: hidden;
  }

  .base-table tr,
  .base-table tr td input {
    text-align: center;
  }

  .base-table table .field {
    width: 100%;
  }
}

/* End Base Table */

/* Start Popup Form */
.pop-up-form {
  z-index: 100;
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
}

.pop-up-form svg {
  float: right;
  cursor: pointer;
  font-size: 25px;
  margin: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.pop-up-form form {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 1px solid;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
}

.pop-up-form form .form-control {
  background-color: inherit !important;
  border: none !important;
  text-align: left;
}

.pop-up-form form .form-control input {
  border-radius: 5px;
  outline: none;
  border: 1px solid;
}

.pop-up-form form .form-control .error {
  color: red;
}

.pop-up-form form .btn {
  width: 50%;
  font-size: 20px;
}

@media screen and (max-width: 576px) {
  .pop-up-form form {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
}

/* End Popup Form */

/* Start Fonts */

/*
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Slab', serif;
*/

/* End Fonts */

/* Import CSS Files */

/* Start Global */
html,
body,
#root {
  padding: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Slab', serif;
}

p,
li {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
}

a {
  color: inherit;
  text-decoration: none;
}

[role=button] {
  cursor: pointer;
}

/* Utilities */
.main-font-family {
  font-family: 'Roboto', sans-serif;
}

.link {
  text-decoration: underline;
}

.hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.loader {
  position: relative;
  height: 100vh;
}

.loader span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: large;
  font-weight: 500;
}

.loader .spinner {
  border: 3px solid blueviolet;
  border-top: 0;
  border-right: 0;
  border-radius: 50%;
  width: 115px;
  height: 115px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-animation: Loader 1.5s cubic-bezier(1, 1, 1, 1) 0s infinite;
          animation: Loader 1.5s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@media all and (max-width: 640px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

/* End Global */

/* Start Header */
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header .icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 50px;
}

.header .icons div {
  position: relative;
  cursor: pointer;
  margin: 0 3px;
}

.header .icons div span.red-dot {
  background-color: red;
  padding: 5px 5px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 0;
  z-index: 1;
}

.header .icons div:nth-child(1) span.red-dot {
  top: 5px;
  right: 3px;
}

.header .profile-info {
  position: relative;
}

.header .profile-info .image {
  max-width: 77px;
  max-height: 77px;
  cursor: pointer;
}

.header .profile-info .info {
  position: absolute;
  top: 90px;
  right: 50%;
  -webkit-transform: translateX(15%);
          transform: translateX(15%);
  width: 180px;
  background-color: #4d4f5c;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  transition: 0.5s;
  z-index: 10;
}

.header .profile-info .info::after {
  content: '';
  border: 10px solid #4d4f5c;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  top: -17px;
  right: 18px;
  z-index: 1;
}

.header .profile-info .info h5 {
  color: #828bb2;
  font-size: 13px;
}

.header .profile-info .info h6 {
  font-size: 12px;
  text-align: center;
}

.header .profile-info .info ul li a {
  font-size: 14px !important;
}

@media screen and (min-width: 991.99px) {
  .header {
    padding-top: 0 !important;
    height: 280px;
  }
}

/* End Header */

/* Start Base Alert */
.base-alert {
  top: -260px;
  left: 30px;
  width: 430px;
  -webkit-filter: drop-shadow(0 0 8px #ffffff20);
          filter: drop-shadow(0 0 8px #ffffff20);
}

@media screen and (max-width: 992px) {
  .base-alert {
    position: static !important;
    margin-top: 20px;
    width: 90%;
  }
}

/* Animation */
@-webkit-keyframes Loader {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
            transform: translate(-50%, -50%) rotate(-360deg);
  }

  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes Loader {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
            transform: translate(-50%, -50%) rotate(-360deg);
  }

  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

